<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col v-for="item in 8" :key="item" cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div v-else>
          <v-row>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="info" size=" 45">
                        <v-icon color="white">mdi-clipboard-list-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ total }}
                      </div>
                      Tatal paiements
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="success" size=" 45">
                        <v-icon color="white">mdi-check</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ success_total }}
                      </div>
                      Réussies
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="warning" size=" 45">
                        <v-icon color="white">mdi-timer-sand</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ pending_total }}
                      </div>
                      En attente
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="error" size=" 45">
                        <v-icon color="white">mdi-alert</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ error_total }}
                      </div>
                      Échecs
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="info" large>mdi-currency-eur</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                        {{ CurrencyFormatting(total_amount) }}
                      </div>
                      Total
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="success" large>mdi-calendar-today</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                        {{ CurrencyFormatting(total_day_amount) }}
                      </div>
                      Aujourd'hui
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="warning" large>mdi-calendar-month</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                            {{ CurrencyFormatting(total_month_amount) }}
                          </div>
                          Mois de {{ setMonth(month) }}
                        </div>
                        <v-spacer/>
                        <v-menu bottom
                                right
                                transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on}">
                            <v-icon color="warning" v-on="on">mdi-chevron-down</v-icon>
                          </template>
                          <v-list dense nav>
                            <v-list-item v-for="(m, i) in months"
                                         :key="i"
                                         :class="m.value === month ? 'warning white--text' : ''"
                                         link
                                         @click="[month=m.value,getEPayments()]">
                              <v-list-item-title>{{ m.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-icon color="error" large>mdi-calendar-check</v-icon>
                    </v-col>
                    <v-col cols="9">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="font-weight-black title pb-1 grey--text text--darken-3 text-no-wrap">
                            {{ CurrencyFormatting(total_year_amount) }}
                          </div>
                          Année {{ year }}
                        </div>
                        <v-spacer/>
                        <v-menu bottom
                                right
                                transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on}">
                            <v-icon color="error" v-on="on">mdi-chevron-down</v-icon>
                          </template>
                          <v-list dense nav>
                            <v-list-item v-for="(y, i) in years"
                                         :key="i"
                                         :class="y === year ? 'error white--text' : ''"
                                         link
                                         @click="[year=y,getEPayments()]">
                              <v-list-item-title>{{ y }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <EPaymentStatistics/>

        <v-card class="rounded-lg shadow">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                color="primary"
                indeterminate
                size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Recherche..."
                  rounded
                  single-line
              ></v-text-field>
            </div>

            <v-spacer/>

            <v-btn color="grey"
                   text
                   @click="$refs.filterDialog.open()">
              <v-icon left>mdi-filter-outline</v-icon>
              Filtre
            </v-btn>

            <v-btn color="grey"
                   text
                   @click="exportExcel()">
              <v-icon left>mdi-download</v-icon>
              Exporter
            </v-btn>

            <v-btn color="grey"
                   icon
                   @click="handleFilter">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">

              <v-simple-table v-if=" ePayments.length">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Transaction</th>
                    <th>Compte</th>
                    <th>montant</th>
                    <th>Commande</th>
                    <th>Getway order ID</th>
                    <th>Message</th>
                    <th>Code</th>
                    <th>Statut</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in  ePayments" :key="i" :class="item.voucher_order_id ? 'order' : 'transaction'">

                    <td>
                      {{ item.transaction ? item.transaction_id : '-' }}
                    </td>

                    <td class="text-no-wrap font-weight-medium pointer"
                        @click="showAccount(item.request_data.account_id)">
                      <v-avatar size="35">
                        <v-img :src="require('@/assets/avatar.png')"/>
                      </v-avatar>
                      {{ item.account && item.account.user ? item.account.user.name : "-" }}
                    </td>
                    <td class="font-weight-medium gifty--text">
                      {{ item.amount ? CurrencyFormatting(item.amount) : '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.voucher_order_id ? item.voucher_order_id : '-' }}
                    </td>

                    <td>
                      {{ item.getway_order_id ? item.getway_order_id : '-' }}
                    </td>

                    <td>
                      <v-tooltip bottom color="gifty" max-width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <div :style="{maxWidth:'150px'}"
                               class="d-inline-block text-truncate"
                               v-bind="attrs"
                               v-on="on">
                            {{ item.message ? item.message : '-' }}
                          </div>
                        </template>
                        <span>{{ item.message ? item.message : '-' }}</span>
                      </v-tooltip>
                    </td>

                    <td>
                      {{ item.code ? item.code : '-' }}
                    </td>

                    <td class="text-center">
                      <v-chip :color="item.status.color" small>
                        {{ item.status.value }}
                      </v-chip>
                    </td>

                    <td class="text-no-wrap">
                      <v-icon :style="{marginTop:'-3px'}"
                              color="gifty"
                              small>mdi-clock-outline
                      </v-icon>
                      {{ dateFormat(item.created_at) }}
                    </td>

                    <td>
                      <div class="d-flex">
                        <v-btn icon @click="remove(item.id)">
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                        <v-btn :disabled="!item.transaction_id" icon @click="downloadPdf(item.id)">
                          <v-icon>mdi-file-pdf-box</v-icon>
                        </v-btn>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div v-else>
                <NoResults @reload="getEPayments"/>
              </div>

            </v-card-text>

            <v-divider v-if="total > 10"/>

            <v-card-actions v-if="total > 10">

              <div :style="{width : '120px'}">
                <v-select v-model="perPage"
                          :items="[10,20,30,50,100]"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined
                ></v-select>
              </div>

              <v-spacer/>


              <v-pagination v-if="total > 10"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="7"
                            @input="getEPayments"></v-pagination>

            </v-card-actions>

          </div>

        </v-card>

      </div>

      <ShowDialog ref="showDialog"/>
      <FilterDialog ref="filterDialog" @filter="handleFilter"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ShowDialog from "./components/ShowDialog";
import FilterDialog from "./components/FilterDialog";
import moment from "moment";
import EPaymentStatistics from "@/views/e_payments/components/EPaymentStatistics";

export default {
  components: {
    EPaymentStatistics,
    ShowDialog,
    FilterDialog,
    NoResults,
  },
  data() {
    return {
      id: null,
      isLoading: false,
      overlay: false,

      keyword: null,
      status: null,
      dates: [],

      ePayments: [],
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      total: 0,
      success_total: 0,
      pending_total: 0,
      error_total: 0,

      total_amount: 0,
      total_day_amount: 0,
      total_month_amount: 0,
      total_year_amount: 0,

      month: moment().format('MM'),
      year: moment().format('YYYY'),
      currentMonth: moment().format('MM'),
      currentYear: moment().format('YYYY')
    };
  },
  methods: {
    showAccount(account_id) {
      let route = this.$router.resolve({path: "/users/" + account_id})
      window.open(route.href, "_blank")
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm')
    },
    getEPayments() {
      this.isLoading = true
      HTTP.get('/v1/e-payments?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword,
          dates: this.dates,
          status: this.status,
          month: this.month,
          year: this.year,
        }
      }).then(res => {
        this.isLoading = false
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.ePayments = res.data.data.data

        this.total = res.data.data.total;
        this.success_total = res.data.success_total
        this.pending_total = res.data.pending_total
        this.error_total = res.data.error_total

        this.total_amount = res.data.total_amount
        this.total_day_amount = res.data.total_day_amount
        this.total_month_amount = res.data.total_month_amount
        this.total_year_amount = res.data.total_year_amount


      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.status = e.status
      this.getEPayments()
    },
    remove(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    deleteItem() {
      this.overlay = true
      let data = {
        e_payment_id: this.id
      }
      HTTP.post('/v1/e-payments/delete', data).then(() => {
        this.overlay = false
        let i = this.ePayments.findIndex(el => el.id === this.id)
        this.$delete(this.ePayments, i)
        this.id = null
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    },
    setMonth(month) {
      return this.months.find(el => el.value === month).name
    },
    refresh() {
      this.month = moment().format('MM')
      this.year = moment().format('YYYY')
    },
    exportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;
      let url = baseUrl + "/api/v1/epayments/export-excel?token=" + token + "&user_id=" + userId + "&status=" + this.status + "&dates=" + this.dates;
      window.open(url, '_blanc')
    },
    downloadPdf(id) {
      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;
      let url = baseUrl + "/api/v1/epayments/export-invoice-pdf?token=" + token + "&user_id=" + userId + "&id=" + id;
      window.open(url, '_blanc')
    }

  },
  created() {
    this.getEPayments()

  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getEPayments()
    },
    keyword() {
      this.getEPayments()
    },
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem()
        this.$is_confirm = false
      }
    },
  },
  computed: {
    months() {
      return [
        {name: 'Janvier', value: "01"},
        {name: 'Février', value: "02"},
        {name: 'Mars', value: "03"},
        {name: 'Avril', value: "04"},
        {name: 'Mai', value: "05"},
        {name: 'Juin', value: "06"},
        {name: 'Juillet', value: "07"},
        {name: 'Août', value: "08"},
        {name: 'Septembre', value: "09"},
        {name: 'Octobre', value: "10"},
        {name: 'Novombre', value: "11"},
        {name: 'Décembre', value: "12"},
      ]
    },
    years() {
      return ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
    },
  },

};
</script>

<style scoped>

.order {
  background-color: rgba(252, 243, 116, 0.945);
}
</style>
